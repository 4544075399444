import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useInView } from "react-intersection-observer"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Projects from "../../components/projects"

const PuebloSonidero = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const { riflaCloudinary } = useStaticQuery(graphql`
    query {
      riflaCloudinary: allCloudinaryMedia(
        filter: { public_id: { regex: "/rgrq/rifla/" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>08. RIF.LA</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2019</h2>
            </div>
            <div className="col-lg-4">
              <h2>Identity, Typeface Design, Web</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-rif">
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between align-items-end">
                  <div className="col-lg-5 d-none d-md-block">
                    <h2>
                      <Link to="/projects/mola">Previous Project(07) ↖</Link>
                    </h2>
                    <h2>
                      <Link to="/projects/typefaces">Typefaces(09) ↗</Link>
                    </h2>
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Typeface Design</p>
            </div>
            <div className="col-lg-7">
              <p>
                The typeface is in reference to the digital fonts in sports.
                Scoreboards have communicated scores to fans throughout history.
                RIF.SANS is a contemporary fresh typeface that explores the past
                and present of digital fonts.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="https://res.cloudinary.com/rwh/video/upload/v1607370354/rgrq/static/rifla/02_ymcils.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Origin</p>
            </div>
            <div className="col-lg-7">
              <p>
                RIF.LA is community-based and gives back to the neighborhood.
                The identity celebrates the philosophy of collaboration and uses
                the color green (RGB) to connect to the journey and experience
                of looking and getting the right sneaker. Green is one of the
                most difficult colors to reproduce in print.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[0].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[1].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[2].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[3].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[4].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[5].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[6].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[7].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[8].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[9].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[10].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[11].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[12].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[13].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[14].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[15].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[16].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[17].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[18].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[19].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[20].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[21].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[22].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[23].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[24].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[25].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[26].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[27].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={riflaCloudinary.edges[28].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-7">
              <p>Rudy Manning (Professor)</p>
              <p>RIF.LA (@riflosangeles)</p>
              <p>Greg Lindy (Lux Typo)</p>
              <p>Isabella Diaz (Hand Model)</p>
              <p>Deysi Diaz (Hand Model)</p>
              <p>The Homies from the laser lab (ArtCenter College of Design)</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default PuebloSonidero
